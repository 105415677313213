import { theme } from '@/config'
import { useMediaQuery } from '@codeleap/web'
import { Breakpoint } from '@codeleap/styles'

export const useIsMobile = () => {
  const mediaQuery = theme.media.down('mobile')

  const isMobile = useMediaQuery(mediaQuery)

  return isMobile
}

export const useIsTablet = () => {
  const mediaQuery = theme.media.down('tabletSmall')

  const isTablet = useMediaQuery(mediaQuery)

  return isTablet
}

export const useMediaQueryDown = (breakpoint: Breakpoint) => {
  const mediaQuery = theme.media.down(breakpoint)

  const isMobile = useMediaQuery(mediaQuery)

  return isMobile
}
