import { api } from '@/config'
import { PaginationResponse, QueryManager } from '@codeleap/query'
import { queryClient } from './queryClient'
import { Notification } from '@/types'
import { firebase } from '../firebase'

const BASE_URL = 'notifications/'

export async function registerDevice(token: string) {
  if (!token) return

  const data = {
    registration_id: token,
    cloud_message_type: 'FCM',
    active: !!token,
  }

  logger.info('Register device', { data }, 'notifications')

  await api.post(BASE_URL + 'device/', data)
}

export async function unregisterDevice() {
  const user = firebase.auth.currentUser

  if (!user?.uid) return

  logger.info('Unregister device', { user: user?.uid }, 'notifications')

  await api.delete(`${BASE_URL}device/${user.uid}/`)
}

export const notificationsManager = new QueryManager<Notification>({
  itemType: {} as Notification,
  name: 'notifications',
  queryClient: queryClient.client,

  listItems: async (limit, offset, filters) => {
    const response = await api.get<PaginationResponse<Notification>>(BASE_URL, {
      params: {
        limit,
        offset,
        ...filters,
      },
    })

    return response.data
  },

  actions: {
    async markRead(manager, notification_id: number | string) {
      const response = await api.patch(BASE_URL + 'mark_read/', {
        id: String(notification_id),
      })

      manager?.refresh()
    
      return response.data
    },
  }
})
