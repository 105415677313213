import { APIClient } from '@/services'
import { UAParser } from 'ua-parser-js'

const geolocationOptions = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0,
}

const getGeolocationData = (): Promise<null | { latitude: number; longitude: number }> => {
  return new Promise((resolve, reject) => {
    if (!navigator?.geolocation) {
      logger.warn('Geolocation is not supported by this browser.')
      return resolve(null)
    }

    if (!navigator?.permissions) {
      logger.warn('Permissions API is not supported by this browser.')
      return resolve(null)
    }

    navigator?.permissions
      .query({ name: 'geolocation' })
      .then((result) => {
        if (['granted', 'prompt'].includes(result?.state)) {
          navigator?.geolocation?.getCurrentPosition(
            (pos) => {
              const coords = pos?.coords
              resolve({
                latitude: coords?.latitude,
                longitude: coords?.longitude,
              })
            },
            (err) => {
              logger.warn(`ERROR(${err?.code}): ${err?.message}`)
              resolve(null)
            },
            geolocationOptions,
          )
        } else if (result?.state === 'denied') {
          logger.log('Geolocation Permission denied')
          resolve(null)
        }
      })
      .catch((error) => {
        logger.error('Error checking geolocation permissions:', error)
        resolve(null)
      })
  })
}

export const getDeviceDebugData = async () => {
  let ip = null
  let geolocation = null

  try {
    const data = await APIClient.UserData.fetchIpAddress()
    ip = data?.ip
  } catch (error) {
    logger.error('Error fetching IP address:', error)
  }

  try {
    geolocation = await getGeolocationData()
  } catch (error) {
    logger.error('Error getting geolocation:', error)
  }

  const userAgent = new UAParser(navigator?.userAgent)
  const parsedUserAgent = userAgent.getResult()

  return {
    ip,
    geolocation,
    connection: navigator?.connection?.effectiveType || 'Unknown',
    deviceMemory: navigator?.deviceMemory || 'Unknown',
    areCookiesEnabled: navigator?.cookieEnabled,
    isPDFViewerEnabled: navigator?.pdfViewerEnabled,
    isOnline: navigator?.onLine,
    platform: navigator?.platform || 'Unknown',
    language: navigator?.language,
    languages: navigator?.languages,
    hardwareConcurrency: navigator?.hardwareConcurrency,
    screen: window?.screen,
    browser: parsedUserAgent.browser,
    cpu: parsedUserAgent.cpu,
    device: parsedUserAgent.device,
    engine: parsedUserAgent.engine,
    os: parsedUserAgent.os,
    ua: parsedUserAgent.ua,
  }
}
